import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const getAppEnv = () => {
    if (typeof window !== 'undefined') {
        const { appEnv } = publicRuntimeConfig;
        return appEnv;
    }

    return process.env.APP_ENV;
};

const getAccessEnv = () => process.env.ACCESS_KEY;

const getAccessOmsEnv = () => {
    if (typeof window !== 'undefined') {
        const { accessOmsKey } = publicRuntimeConfig;
        return accessOmsKey;
    }

    return process.env.ACCESS_OMS_KEY;
};

const getChatConfigUsername = () => process.env.CHAT_CONFIG_USERNAME;

const getChatConfigPassword = () => process.env.CHAT_CONFIG_PASSWORD;

const getEncryptEnv = () => process.env.ENCRYPTION_KEY;

const getMidtransClientKey = () => process.env.MIDTRANS_CLIENT_KEY;

const getGmapKey = () => process.env.GMAP_KEY || process.env.NEXT_PUBLIC_GMAP_KEY;

const getGeocodingKey = () => process.env.GEOCODING_KEY || process.env.NEXT_PUBLIC_GEOCODING_KEY;

const getRecaptchaSiteKey = () => process.env.RECAPTCHA_SITE_KEY || process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

const getRecaptchaSecretKey = () => process.env.RECAPTCHA_SECRET_KEY || process.env.NEXT_PUBLIC_RECAPTCHA_SECRET_KEY;

const getMerchantId = () => process.env.GOOGLE_MERCHANT_ID || process.env.NEXT_PUBLIC_GOOGLE_MERCHANT_ID;

module.exports = {
    getAppEnv,
    getAccessEnv,
    getChatConfigUsername,
    getChatConfigPassword,
    getEncryptEnv,
    getAccessOmsEnv,
    getMidtransClientKey,
    getGmapKey,
    getGeocodingKey,
    getRecaptchaSiteKey,
    getRecaptchaSecretKey,
    getMerchantId,
};
